import React, { useEffect, useState } from "react";
import { Button } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useApplication } from "../contexts/ApplicationContext";
import moment from "moment";

const SubscriptionInfoPage = () => {
  const { user } = useApplication();
  const navigate = useNavigate();

  const displaySubscriptionInfo = () => {
    return (
      <div>
        <h3>Assinaturas Ativas:</h3>
        <div>
          <p>Assinatura Relinq PRO</p>
          <p>
            <strong>Ativa até:</strong>{" "}
            {moment(user?.Company?.planExpirationDate, "yyyy-MM-DD").format(
              "DD/MM/yyyy"
            )}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col min-h-screen p-4">
      <h1 className="text-xl font-bold">Informações da Assinatura</h1>
      {displaySubscriptionInfo()}
      <Button className="mt-4" onClick={() => navigate("/dashboard")}>
        Voltar para o Dashboard
      </Button>
    </div>
  );
};

export default SubscriptionInfoPage;
