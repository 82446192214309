import {
  Accordion,
  Alert,
  Badge,
  Button,
  Label,
  Select,
  TextInput,
} from "flowbite-react";
import { useState } from "react";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import { FaApple, FaTrash } from "react-icons/fa";
import {
  MdAndroid,
  MdCategory,
  MdCelebration,
  MdDesignServices,
  MdOutlineAppShortcut,
  MdOutlineIosShare,
} from "react-icons/md";
import { calculateTimeService } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { COMPANIES } from "../../config/apiConfig";
import { BsAndroid2 } from "react-icons/bs";

function FirstAccessForm({ invalidateUserData }) {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [service, setService] = useState({
    name: "",
    price: "",
    durationMinutes: "",
    category: "",
  });
  const [errorMessageCategory, setErrorMessageCategory] = useState(null);
  const [errorMessageService, setErrorMessageService] = useState(null);

  const handleComplete = async () => {
    try {
      setIsLoadingSubmit(true);
      await axios.put(COMPANIES.UPDATE_FIRST_ACCESS, {
        categories,
        service,
      });

      invalidateUserData();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingSubmit(false);
    }
  };
  const tabChanged = ({ prevIndex, nextIndex }) => {
    console.log("prevIndex", prevIndex);
    console.log("nextIndex", nextIndex);
  };

  const checkValidateTabCategory = () => {
    if (categories?.length === 0) {
      return false;
    }
    return true;
  };

  const checkEmptyValuesService = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === "") {
          return false;
        }
      }
    }
    return true;
  };

  const handleCreateCategory = () => {
    setCategories((prevState) => [...prevState, category]);
    setCategory("");
  };

  const handleDeleteCategory = (index) => {
    setCategories((prevCategories) =>
      prevCategories.filter((_, i) => i !== index)
    );
  };

  const errorMessageEmptyCategory = () => {
    setErrorMessageCategory("Você precisa adicionar pelo menos 1 categoria!");
  };

  const errorMessageEmptyService = () => {
    setErrorMessageService("Você precisa preencher todos os campos!");
  };

  const handleChangeService = (e) => {
    const { id, value } = e.target;
    setService((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  console.log(service);

  const backTemplate = (handlePrevious) => {
    return (
      <Button className="bg-gray-500 inline-block" onClick={handlePrevious}>
        Voltar
      </Button>
    );
  };

  const nextTemplate = (handleNext) => {
    return (
      <Button className="bg-primary inline-block" onClick={handleNext}>
        Próximo
      </Button>
    );
  };

  return (
    <div
      className="fixed top-0 bottom-0 left-0 right-0 bg-gray-200"
      style={{ zIndex: "1000" }}
    >
      <div className="overflow-y-scroll h-full lg:max-w-screen-md mx-auto md:bg-white rounded my-2 shadow">
        <FormWizard
          showProggressBar
          shape="circle"
          color="var(--primary)"
          onComplete={handleComplete}
          onTabChange={tabChanged}
          stepSize="sm"
          backButtonTemplate={backTemplate}
          nextButtonTemplate={nextTemplate}
          finishButtonTemplate={(handleComplete) => (
            <Button
              className="btn-primary"
              isProcessing={isLoadingSubmit}
              onClick={handleComplete}
            >
              Tudo Pronto!
            </Button>
          )}
        >
          <FormWizard.TabContent title="Saudações" icon={<MdCelebration />}>
            <img src="assets/logo_black.png" className="w-28 mx-auto" />
            <h1 className="text-center mt-1 font-semibold">
              Seja bem vindo a Relinq! 🚀
            </h1>

            <p className=" mt-1 mb-1 md:mb-4">
              O melhor sistema de agendamentos do mercado.
            </p>

            <ul className="text-left flex flex-col gap-2 bg-gray-300 rounded shadow-md p-2 text-sm md:text-base w-full md:max-w-screen-md mx-auto">
              <p>
                Antes de começar a realizar os seus agendamentos, vamos
                configurar:
              </p>
              <li>1️⃣ Categorias para organizar seus serviços e produtos.</li>
              <li>2️⃣ O seu primeiro serviço para começar a agendar.</li>
            </ul>
          </FormWizard.TabContent>
          <FormWizard.TabContent title="Categoria" icon={<MdCategory />}>
            <p className="mt-4 font-semibold mb-2">
              Que tal começar criando uma categoria?
            </p>
            <div className="flex gap-2 items-center">
              <TextInput
                className="mt-1 h-full flex-1"
                placeholder="Ex: Cabelos, Consultas, Aulas, etc.."
                onChange={(e) => setCategory(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleCreateCategory();
                  }
                }}
                value={category}
              />

              <Button onClick={handleCreateCategory}>Adicionar</Button>
            </div>
            {categories?.length > 0 && (
              <ul className="gap-2 flex flex-col mt-3 mb-3">
                {categories.map((category, index) => (
                  <li
                    className="rounded p-2 bg-white md:bg-gray-100 shadow text-sm flex gap-2 items-center justify-between capitalize"
                    key={index}
                  >
                    <div className="flex items-center gap-2">
                      <MdCategory /> <span>{category}</span>
                    </div>
                    <Button
                      className="bg-gray-600"
                      size="xs"
                      onClick={() => handleDeleteCategory(index)}
                    >
                      <FaTrash fontSize={10} />
                    </Button>
                  </li>
                ))}
              </ul>
            )}
            <div className="mt-2 text-xs border border-gray-300 p-2 rounded">
              Obs: A categoria ajuda na organização de seus serviços e produtos
              de forma prática e eficiente!
            </div>
            {errorMessageCategory && categories.length === 0 && (
              <Alert withBorderAccent color="failure" className="mt-4">
                {errorMessageCategory}
              </Alert>
            )}
          </FormWizard.TabContent>
          <FormWizard.TabContent
            title="Serviço"
            icon={<MdDesignServices />}
            isValid={checkValidateTabCategory()}
            validationError={errorMessageEmptyCategory}
          >
            <p className="mt-4 font-semibold">
              Agora bora criar seu primeiro serviço?
            </p>
            <div className="rounded-md px-2 py-4 bg-white md:bg-gray-100 my-4 shadow-md">
              <div className="text-left">
                <div className="mb-1 block">
                  <Label htmlFor="name" value="Nome do serviço" />
                </div>
                <TextInput
                  id="name"
                  type="text"
                  required
                  value={service.name}
                  onChange={handleChangeService}
                />
              </div>
              <div className="text-left mt-2">
                <div className="mb-1 block">
                  <Label htmlFor="price" value="Preço do serviço (R$)" />
                </div>
                <TextInput
                  id="price"
                  type="number"
                  required
                  step="0.01"
                  min={0}
                  value={service.price}
                  onChange={handleChangeService}
                />
              </div>
              <div className="text-left mt-2">
                <div className="mb-1 block">
                  <Label
                    htmlFor="durationMinutes"
                    value="Tempo médio de duração"
                  />
                </div>
                <Select
                  id="durationMinutes"
                  required
                  value={service.durationMinutes}
                  onChange={handleChangeService}
                >
                  <option value="">Selecione</option>
                  {calculateTimeService().map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="text-left mt-2">
                <div className="mb-1 block">
                  <Label htmlFor="category" value="Categoria do serviço" />
                </div>
                <Select
                  id="category"
                  required
                  value={service.category}
                  onChange={handleChangeService}
                >
                  <option value="">Selecione</option>
                  {categories?.map((category, index) => (
                    <option key={index}>{category}</option>
                  ))}
                </Select>
              </div>
              {errorMessageService && (
                <Alert className="mt-4" withBorderAccent color="failure">
                  {errorMessageService}
                </Alert>
              )}
            </div>
          </FormWizard.TabContent>
          <FormWizard.TabContent
            title="Aplicativo"
            icon={<MdOutlineAppShortcut />}
            isValid={checkEmptyValuesService(service)}
            validationError={errorMessageEmptyService}
          >
            <p className="mt-4 font-semibold">
              Estamos muito felizes em tê-lo(a) conosco. Aproveite todas as
              vantagens de um sistema que torna sua rotina mais prática e
              eficiente. 🚀
            </p>

            <p className="mt-5">
              Você sabia que nosso sistema está disponível como app também?
            </p>
            <div className="flex flex-col md:flex-row items-center justify-center gap-2 mt-5">
              <a
                className="flex items-center rounded-md w-fit p-2 gap-2 bg-white shadow-md border border-gray-200"
                href="https://apps.apple.com/br/app/relinq/id6739940867"
                target="_blank"
              >
                <FaApple size={30} />
                <span>Baixar na App Store</span>
              </a>
              <a className="flex items-center rounded-md w-fit p-2 gap-2 bg-white shadow-md border border-gray-200">
                <BsAndroid2 size={30} />
                <span>Em breve para Android</span>
              </a>
            </div>
          </FormWizard.TabContent>
        </FormWizard>
        {/* add style */}
        <style>{`
        @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
        .wizard-card-footer{
          display: flex;
          justify-content: center;
          gap: 10px;
          margin-top: 20px;
        }
      `}</style>
      </div>
    </div>
  );
}

export default FirstAccessForm;
