import { useEffect, useState } from "react";
import DrawerLoading from "../components/DrawerLoading";
import { COMPANIES } from "../config/apiConfig";
import axios from "../config/axiosInstance";
import { useQuery } from "react-query";

const ReportPage = () => {
  const phrases = [
    "Carregando os dados...",
    "Preparando algo incrível...",
    "Quase pronto, só mais um momento...",
    "Finalizando os últimos ajustes...",
  ];

  const [currentPhrase, setCurrentPhrase] = useState(phrases[0]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % phrases.length;
        setCurrentPhrase(phrases[nextIndex]);
        return nextIndex;
      });
    }, 2000);

    return () => clearInterval(interval);
  }, [phrases]);

  const {
    isLoading,
    error,
    data: report,
  } = useQuery(["report"], async () => {
    const data = await axios.get(`${COMPANIES.GENERATE_REPORT}`);

    return data.data.report;
  });

  return (
    <div
      className="bg-white p-4 rounded-md shadow-md relative"
      style={{ minHeight: "500px" }}
    >
      {!isLoading ? (
        <div dangerouslySetInnerHTML={{ __html: report }}></div>
      ) : (
        <>
          <DrawerLoading />
          <div className="flex justify-center items-center h-screen bg-gray-100">
            <p className="text-lg text-gray-600 italic animate-fade">
              {currentPhrase}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default ReportPage;
