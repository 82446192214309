import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import CustomersTable from "../components/tables/CustomersTable";
import Calendar from "../components/Calendar";
import RequireAuth from "./AuthRouter";
import Container from "./Container";
import LoginPage from "../pages/LoginPage";
import NotFoundPage from "../pages/NotFoundPage";
import Dashboard from "../components/Dashboard";
import CategoriesTable from "../components/tables/CategoriesTable";
import ProfessionalsTable from "../components/tables/ProfessionalsTable";
import ServicesTable from "../components/tables/ServicesTable";
import MyProfilePage from "../pages/MyProfilePage";
import CommandsTable from "../components/tables/CommandsTable";
import CompanyPage from "../pages/CompanyPage";
import InvoicesTable from "../components/tables/InvoicesTable";
import ProductsTable from "../components/tables/ProductsTable";
import TutorialsPage from "../pages/TutorialsPage";
import PackagesTable from "../components/tables/PackagesTable";
import RecordsTable from "../components/tables/RecordsTable";
import SiteOnlinePage from "../pages/SiteOnlinePage";
import ReviewsPage from "../pages/ReviewsPage";
import PaymentPage from "../pages/PaymentPage";
import PaymentConfirm from "../pages/PaymentConfirm";
import ReminderPage from "../pages/ReminderPage";
import FirstAccessForm from "../components/forms/FirstAccessForm";
import { useApplication } from "../contexts/ApplicationContext";
import ReportPage from "../pages/ReportPage";
import RegisterPage from "../pages/RegisterPage";
import SupportPage from "../pages/SupportPage";
import { Capacitor } from "@capacitor/core";
import AppStorePage from "../pages/AppStorePage";
import SubscriptionInfoPageApp from "../pages/SubscriptionInfoPageApp";
import SubscriptionInfoPage from "../pages/SubscriptionInfoPage";

const AppRouter = () => {
  const platform = Capacitor.getPlatform();
  const { user, invalidateUserData } = useApplication();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/cadastro" element={<RegisterPage />} />

        <Route
          path="/"
          element={
            <RequireAuth>
              <Container>
                <Outlet />
              </Container>
            </RequireAuth>
          }
        >
          <Route path="/" element={<Calendar />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="invoice" element={<InvoicesTable />} />
          <Route path="calendar" element={<Calendar />} />
          <Route path="commands" element={<CommandsTable />} />
          <Route path="customers" element={<CustomersTable />} />
          <Route path="professionals" element={<ProfessionalsTable />} />
          <Route path="products" element={<ProductsTable />} />
          <Route path="services" element={<ServicesTable />} />
          <Route path="categories" element={<CategoriesTable />} />
          <Route path="online-site" element={<SiteOnlinePage />} />
          <Route path="packages" element={<PackagesTable />} />
          <Route path="records" element={<RecordsTable />} />
          <Route path="reviews" element={<ReviewsPage />} />
          <Route path="tutorials" element={<TutorialsPage />} />
          <Route path="company" element={<CompanyPage />} />
          <Route path="profile" element={<MyProfilePage />} />
          <Route
            path="payment"
            element={
              ["ios", "android"].includes(platform) ? (
                <AppStorePage />
              ) : (
                <PaymentPage />
              )
            }
          />
          <Route
            path="subscription"
            element={
              ["ios", "android"].includes(platform) ? (
                <SubscriptionInfoPageApp />
              ) : (
                <SubscriptionInfoPage />
              )
            }
          />
          <Route path="/support" element={<SupportPage />} />
          <Route path="payment-confirm" element={<PaymentConfirm />} />
          <Route path="reminders" element={<ReminderPage />} />
          <Route path="report" element={<ReportPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
      {user?.Company?.isFirstAccess && (
        <FirstAccessForm invalidateUserData={invalidateUserData} />
      )}
    </BrowserRouter>
  );
};

export default AppRouter;
