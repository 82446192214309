import React, { useEffect, useState } from "react";
import { Purchases, LOG_LEVEL } from "@revenuecat/purchases-capacitor";
import { FaCheck } from "react-icons/fa";
import { Button } from "flowbite-react";
import { useApplication } from "../contexts/ApplicationContext";
import axios from "../config/axiosInstance";
import { PROFESSIONALS } from "../config/apiConfig";
import { useNavigate } from "react-router-dom";
import { saveUserInWindow } from "../utils/utils";

const AppStorePage = () => {
  const navigate = useNavigate();
  const { invalidateUserData } = useApplication();
  const [availablePackages, setAvailablePackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    (async function () {
      setLoading(true);
      await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
      await Purchases.configure({
        apiKey: "appl_uQEDSAvhxCxisCPYqGlswnxIJCP",
      });

      const offerings = await Purchases.getOfferings();

      if (offerings.current !== null) {
        setAvailablePackages(offerings.current.availablePackages);
      } else {
        setAvailablePackages([]);
      }

      setLoading(false);
    })();
  }, []);

  const purchasePackage = async (packageToBuy) => {
    try {
      setProcessing(true);
      const purchase = await Purchases.purchasePackage({
        aPackage: packageToBuy,
      });

      if (purchase && purchase.customerInfo.originalAppUserId) {
        await axios.put(PROFESSIONALS.UPDATE_PROFESSIONAL_REVENUECAT_ID, {
          appUserId: purchase.customerInfo.originalAppUserId,
        });

        const response = await axios.get(PROFESSIONALS.RENEW_TOKEN);

        if (response.data.token) {
          localStorage.setItem("token", response.data.token);

          saveUserInWindow();
        }

        console.log("Compra realizada com sucesso:", purchase);

        invalidateUserData();

        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Erro ao realizar a compra:", error);
    } finally {
      setProcessing(false);
    }
  };

  const restorePurchases = async () => {
    try {
      const restoredPurchases = await Purchases.restorePurchases();
      console.log("Compras restauradas:", restoredPurchases);
      if (restoredPurchases.length > 0) {
        alert("Compras restauradas com sucesso!");
      } else {
        alert("Não há compras para restaurar.");
      }
    } catch (error) {
      console.error("Erro ao restaurar compras:", error);
      alert("Erro ao tentar restaurar compras.");
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      {!loading ? (
        <>
          <header className="p-4">
            <div className="rounded-lg bg-primary w-20 h-20 flex items-center justify-center p-4 mx-auto mb-2">
              <img src="/assets/logo_mini.png" />
            </div>
            <h1 className="text-xl font-bold text-center">
              Aproveite todas as funcionalidades do nosso sistema de
              agendamento!
            </h1>
            <div className="mt-4 mb-2 font-semibold">
              Com a assinatura PRO, você pode aproveitar:
            </div>
            <ul className="flex flex-col gap-2">
              <li className="flex gap-2 items-center">
                <FaCheck
                  className="text-green-500"
                  style={{ width: "16px", minWidth: "16px" }}
                />
                <span>Adicione múltiplos profissionais ao sistema.</span>
              </li>
              <li className="flex gap-2 items-center">
                <FaCheck
                  className="text-green-500"
                  style={{ width: "16px", minWidth: "16px" }}
                />
                <span>Gestão de faturamento.</span>
              </li>
              <li className="flex gap-2 items-center">
                <FaCheck
                  className="text-green-500"
                  style={{ width: "16px", minWidth: "16px" }}
                />
                <span>Gestão de pacotes.</span>
              </li>
              <li className="flex gap-2 items-center">
                <FaCheck
                  className="text-green-500"
                  style={{ width: "16px", minWidth: "16px" }}
                />
                <span>Geração inteligente de relatórios semanais.</span>
              </li>
              <li className="flex gap-2 items-center">
                <FaCheck
                  className="text-green-500"
                  style={{ width: "16px", minWidth: "16px" }}
                />
                <span>Suporte com Inteligência artificial.</span>
              </li>
              <li className="flex gap-2 items-center">
                <FaCheck
                  className="text-green-500"
                  style={{ width: "16px", minWidth: "16px" }}
                />
                <span>
                  Lembretes automáticos de agendamento para seus clientes.
                </span>
              </li>
            </ul>
          </header>
          <main className="flex-grow p-4">
            <>
              {availablePackages.length > 0 ? (
                availablePackages.map((pkg) => (
                  <div key={pkg.identifier} className="mb-2">
                    <div className="font-semibold">Relinq PRO</div>
                    <div className="mb-1 text-sm text-gray-700 font-semibold">
                      Assinatura auto-renovável mensal por apenas R$29,90/mês
                    </div>
                    <Button
                      onClick={() => purchasePackage(pkg)}
                      className="w-full primary"
                      disabled={processing}
                      isProcessing={processing}
                    >
                      {processing ? "Processando..." : "Assine agora"}
                    </Button>
                  </div>
                ))
              ) : (
                <p>Nenhum produto disponível</p>
              )}
            </>

            <Button
              onClick={restorePurchases}
              className="w-full bg-gray-500 text-white"
              disabled={processing}
              isProcessing={processing}
            >
              {processing ? "Restaurando..." : "Restaurar Compras"}
            </Button>

            <div className="text-center mt-2 text-sm text-gray-600">
              <a
                href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                target="_blank"
                className="underline"
              >
                Termos de Uso (EULA)
              </a>
              <span> | </span>
              <a
                href="https://relinq.com.br/politica-de-privacidade"
                target="_blank"
                className="underline"
              >
                Política de Privacidade
              </a>
            </div>
          </main>
        </>
      ) : (
        <div className="h-96 flex items-center justify-center font-semibold text-xl">
          Carregando planos...
        </div>
      )}
    </div>
  );
};

export default AppStorePage;
