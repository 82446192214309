import React, { useEffect, useRef, useState } from "react";
import { LOGIN, SUPPORT } from "../config/apiConfig";
import { useApplication } from "../contexts/ApplicationContext";
import axios from "../config/axiosInstance";
import { Alert } from "flowbite-react";
import ModalConfirmAdditionalPlan from "../components/modals/ModalConfirmAdditionalPlan";

const SupportPage = () => {
  const { user } = useApplication();
  const [message, setMessage] = useState({ user: "", message: "" });
  const [messages, setMessages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    setMessages([
      {
        user: "RELINA",
        message: `Olá, sou a Relina! A Inteligência artificial da Relinq. Me pergunte algo como: Como criar um agendamento? `,
      },
    ]);
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    setIsSubmitting(true);
    setMessages((prev) => [...prev, message]);
    try {
      const msg = message?.message;
      setMessage({ user: "", message: "" });
      const data = await axios.post(SUPPORT.SEND_MESSAGE, {
        message: msg,
      });
      setMessages((prev) => [
        ...prev,
        { user: "RELINA", message: data.data.messageReply },
      ]);
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Alert withBorderAccent className="mb-2" color="purple">
        Estamos animados em apresentar a <strong>Versão Beta</strong> da nossa
        nova funcionalidade! Essa versão está disponível para que você possa
        experimentar em primeira mão e nos ajudar a torná-la ainda melhor.
      </Alert>
      <div
        className="flex flex-col mx-auto p-4 bg-white shadow-md rounded-md"
        style={{ height: "650px" }}
      >
        <div className="flex-grow overflow-y-scroll mb-4 flex flex-col gap-4">
          {messages.map((msg, index) => (
            <div className="flex gap-2">
              {msg.user === "RELINA" && (
                <img
                  src="/assets/relina.png"
                  className="rounded-full object-cover w-10 h-10"
                />
              )}

              <div
                key={index}
                className={`p-2 rounded-lg text-black text-sm md:text-base ${
                  msg.user === "USER"
                    ? "ml-auto bg-red-100"
                    : "mr-auto bg-gray-200"
                }`}
                style={{ maxWidth: "500px" }}
              >
                {msg.message}
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        <div className="mt-auto">
          {isSubmitting && (
            <div className="text-gray-500 text-xs mb-2">
              Relina está digitando...
            </div>
          )}
          <form onSubmit={onSubmit}>
            <div className="flex">
              <input
                type="text"
                className="flex-grow border border-gray-300 rounded-lg p-2 flex-1"
                value={message.message}
                onChange={(e) =>
                  setMessage({ user: "USER", message: e.target.value })
                }
                placeholder="Digite sua mensagem"
                minLength={3}
                required
              />
              <button
                className="ml-2 bg-primary text-white rounded-lg px-4"
                type="submit"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>

      <ModalConfirmAdditionalPlan />
    </>
  );
};

export default SupportPage;
