import moment from "moment";

export const calculateCurrentDates = (view, date) => {
  const dates = [];
  const today = moment(date);

  switch (view) {
    case "month":
      const startDate = moment().startOf("year");

      for (let i = 0; i < 12; i++) {
        dates.push(moment(startDate).add(i, "month"));
      }
      break;
    case "week":
      const firstDayOfWeek = moment(today).startOf("week");
      const lastDayOfWeek = moment(today).endOf("week");
      let currentDay = moment(firstDayOfWeek);

      while (currentDay.isSameOrBefore(lastDayOfWeek, "day")) {
        dates.push(moment(currentDay));
        currentDay.add(1, "day");
      }

      break;
    case "day":
      const startOfMonth = moment(today).startOf("month");
      const endOfMonth = moment(today).endOf("month").format("DD");

      for (let day = 0; day < +endOfMonth; day++) {
        dates.push(moment(startOfMonth).add(day, "day"));
      }

      break;
  }

  return dates;
};

export const calculateDaysDiffCurrentDate = (date) => {
  const currentDate = moment();
  return currentDate.diff(moment(date), "days");
};
