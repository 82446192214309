import React, { useEffect, useState } from "react";
import useLogin from "../hooks/useLogin";
import { LOGIN } from "../config/apiConfig";
import { Alert, Button, Checkbox, Label } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import Input from "../components/inputs/Input";
import { useForm } from "react-hook-form";
import PhoneInput from "../components/inputs/PhoneInput";
import axios from "axios";
import { isMobile } from "react-device-detect";

const RegisterPage = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setError(false);
    setIsSubmitting(true);
    try {
      await axios.post(LOGIN.POST_SIGNUP, data);

      navigate("/login");
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (!isMobile) {
      window.location.href = "https://relinq.com.br/register";
    }
  }, []);

  return (
    <section className="bg-white p-4 overflow-y-scroll">
      <Link to="/login">
        <HiOutlineArrowNarrowLeft fontSize={30} className="text-gray-600" />
      </Link>
      <img className="w-32 mx-auto mt-10" src="/assets/logo_black.png" />
      <h1 className="font-semibold text-xl text-center text-gray-700">
        Seja bem-vindo a Relinq!
      </h1>
      <div className="text-gray-500 text-center">
        Cadastre-se de forma rápida e segura para começar a agendar com
        facilidade. <br /> Nós cuidamos do resto! 🚀
      </div>

      <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <Input
            type="text"
            label="Nome completo*"
            name="name"
            required={true}
            register={register}
            error={errors?.name && "Este campo é obrigatório"}
          />
        </div>

        <div className="mb-4">
          <Input
            type="email"
            label="E-mail *"
            name="email"
            required={true}
            register={register}
            error={errors?.email && "Este campo é obrigatório"}
          />
        </div>

        <div className="mb-4">
          <PhoneInput
            label="Celular *"
            name="phoneNumber"
            required={true}
            register={register}
            error={errors?.phoneNumber && "Este campo é obrigatório"}
          />
        </div>

        <div className="mb-4">
          <Input
            type="text"
            label="Nome da empresa *"
            name="company"
            required={true}
            register={register}
            error={errors?.company && "Este campo é obrigatório"}
          />
        </div>

        <div className="mb-4">
          <Input
            type="password"
            label="Senha *"
            name="password"
            required={true}
            register={register}
            error={errors?.password && "Este campo é obrigatório"}
          />
        </div>

        <div className="flex items-center gap-2 mb-6">
          <Checkbox id="remember" register={register} required={true} />
          <Label htmlFor="remember" className="text-xs">
            Eu li e aceito os{" "}
            <a
              className="underline"
              href="https://relinq.com.br/politica-de-privacidade"
            >
              Termos de Uso e a Política de Privacidade.
            </a>
          </Label>
        </div>

        {error && (
          <Alert className="mb-3" color="failure" withBorderAccent>
            Ops! Ocorreu algum erro com seu cadastro. Mas não se preocupe, você
            pode tentar novamente mais tarde.
          </Alert>
        )}

        <Button
          type="submit"
          className="w-full primary shadow mb-10 h-12 flex items-center"
          isProcessing={isSubmitting}
          processingSpinner={
            <AiOutlineLoading className="h-6 w-6 animate-spin" />
          }
          disabled={isSubmitting}
        >
          Cadastrar
        </Button>
      </form>
    </section>
  );
};

export default RegisterPage;
