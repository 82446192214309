import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { Avatar, Badge, Button, Dropdown } from "flowbite-react";
import { permissionChecker, removeToken } from "../utils/permissions";
import { useApplication } from "../contexts/ApplicationContext";
import { IoMenuOutline } from "react-icons/io5";
import { RiArrowDropDownLine } from "react-icons/ri";
import Notification from "./Notification";

const Sidebar = () => {
  const { user, userPermission } = useApplication();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [titlePage, setTitlePage] = useState("Dashboard");
  const sidebarRef = useRef(null);
  const menuBtnRef = useRef(null);

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const menuLinks = [
    {
      name: "Dashboard",
      link: "/dashboard",
      icon: "equalizer",
      permission: ["USER", "USER_CALENDARS"],
      isPro: false,
    },
    {
      name: "Faturamento",
      link: "/invoice",
      icon: "attach_money",
      permission: ["ADMIN"],
      isPro: true,
    },
    {
      name: "Agenda",
      link: "/calendar",
      icon: "today",
      permission: ["USER", "USER_CALENDARS"],
      isPro: false,
    },
    {
      name: "Comandas",
      link: "/commands",
      icon: "receipt_long",
      permission: ["USER", "USER_CALENDARS"],
      isPro: false,
    },
    {
      name: "Clientes",
      link: "/customers",
      icon: "group",
      permission: ["USER", "USER_CALENDARS"],
      isPro: false,
    },
    {
      name: "Profissionais",
      link: "/professionals",
      icon: "hail",
      permission: ["USER_CALENDARS"],
      isPro: false,
    },
    {
      name: "Produtos",
      link: "/products",
      icon: "shopping_bag",
      permission: ["USER_CALENDARS"],
      isPro: false,
    },
    {
      name: "Serviços",
      link: "/services",
      icon: "design_services",
      permission: ["USER", "USER_CALENDARS"],
      isPro: false,
    },
    {
      name: "Categorias",
      link: "/categories",
      icon: "category",
      permission: ["USER", "USER_CALENDARS"],
      isPro: false,
    },
    {
      name: "Lembretes",
      link: "/reminders",
      icon: "notifications",
      permission: ["ADMIN"],
      isPro: true,
    },
    {
      name: "Site Online",
      link: "/online-site",
      icon: "public",
      permission: ["USERS_CALENDARS"],
      isPro: false,
    },
    {
      name: "Relina IA",
      link: "/support",
      icon: "tips_and_updates",
      permission: ["USER"],
      isPro: true,
    },
    {
      name: "Pacotes",
      link: "/packages",
      icon: "grid_view",
      permission: ["USERS_CALENDARS"],
      isPro: true,
    },
    {
      name: "Formulários",
      link: "/records",
      icon: "assignment",
      permission: ["USER"],
      isPro: true,
    },
    // {
    //   name: "Avaliações",
    //   link: "/reviews",
    //   icon: "star_half",
    //   permission: ["USER"],
    //   isPro: false,
    // },
    // {
    //   name: "Planos Adicionais",
    //   link: "/additional-plans",
    //   icon: "add_business",
    //   permission: ["ADMIN"],
    //   isPro: false,
    // },
  ];

  const closeSidebar = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setTitlePage(
      menuLinks.find((link) => link.link === location.pathname)?.name ||
        "Dashboard"
    );

    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !menuBtnRef.current.contains(event.target) &&
        isOpen
      ) {
        closeSidebar();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [location.pathname, isOpen]);

  return (
    <>
      <aside
        id="logo-sidebar"
        ref={sidebarRef}
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform bg-white border-r sm:translate-x-0 ${
          isOpen ? "" : "-translate-x-full"
        } dark:bg-gray-800 dark:border-gray-700`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 bg-white dark:bg-gray-800 pt-3">
          <img
            src="/assets/logo.png"
            alt="Logo da Relinq"
            style={{ height: "35px" }}
            className="mx-auto mb-4"
          />
          <div className="flex items-center gap-1 justify-center mb-2 text-white font-semibold text-xs">
            Plano atual:{" "}
            <Badge style={{ fontSize: "10px" }}>{user?.Company?.planId}</Badge>
          </div>
          <div className="overflow-y-auto pr-2" style={{ maxHeight: "85vh" }}>
            <ul className="space-y-2 font-medium">
              {menuLinks.map((menuLink, index) => (
                <React.Fragment key={index}>
                  {permissionChecker(menuLink.permission, userPermission) && (
                    <li className="relative">
                      <Link
                        to={menuLink.link}
                        className={`flex items-center p-2 text-gray-900 rounded-lg group ${
                          location.pathname === menuLink.link &&
                          "active shadow-lg"
                        }`}
                        onClick={handleToggleSidebar}
                      >
                        <Icon>{menuLink.icon}</Icon>
                        <span className="ms-3">{menuLink.name}</span>
                      </Link>
                      {menuLink.isPro && user?.Company?.planId !== "PRO" && (
                        <Badge
                          className="absolute right-2 top-2.5"
                          color="cyan"
                          style={{ fontSize: "8px" }}
                        >
                          PRO
                        </Badge>
                      )}
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </div>
        </div>
      </aside>
      <div className="sm:ml-64 mt-0">
        <div className="container mx-auto">
          <div className="mainbar px-5">
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <img
                  src="/assets/logo_mini.png"
                  style={{ height: "23px", marginTop: "-3px" }}
                />
                <h2 className="font-bold">{titlePage}</h2>
                <div className="w-fit notifications-popup">
                  <Notification />
                </div>
              </div>
              <div className="flex items-center gap-3 avatar">
                <h2 className="hidden md:block">Bem vindo, {user?.name}</h2>
                <Dropdown
                  label={
                    <div className="flex gap-1 items-center">
                      <RiArrowDropDownLine fontSize={25} />
                      <div className="relative">
                        <Avatar
                          rounded
                          size="sm"
                          img={user?.imageUrl}
                          className="outline outline-2 outline-white rounded-full focus:outline-none focus-visible:outline-none"
                        />
                        <IoMenuOutline className="bg-primary absolute right-0 top-5 rounded-full outline outline-2 outline-white" />
                      </div>
                    </div>
                  }
                  arrowIcon={false}
                  inline
                >
                  <Dropdown.Header>
                    <span className="block truncate text-sm font-medium">
                      {user?.email}
                    </span>
                  </Dropdown.Header>
                  <Dropdown.Item className="py-0 px-0">
                    <Link to="/profile" className="w-full py-2 text-left px-4">
                      Minha conta
                    </Link>
                  </Dropdown.Item>
                  {permissionChecker("ADMIN", userPermission) && (
                    <>
                      <Dropdown.Item className="py-0 px-0">
                        <Link
                          to="/company"
                          className="w-full py-2 text-left px-4"
                        >
                          Minha empresa
                        </Link>
                      </Dropdown.Item>
                      {user?.Company?.planId === "PRO" && (
                        <Dropdown.Item className="py-0 px-0">
                          <Link
                            to="/subscription"
                            className="w-full py-2 text-left px-4"
                          >
                            Gerenciar assinatura
                          </Link>
                        </Dropdown.Item>
                      )}
                    </>
                  )}

                  <Dropdown.Item className="py-0 px-0">
                    <Link
                      to="/tutorials"
                      className="w-full py-2 text-left px-4"
                    >
                      Tutoriais
                    </Link>
                  </Dropdown.Item>

                  <Dropdown.Divider />
                  <Dropdown.Item className="py-0 px-0">
                    {user?.Company?.planId === "PRO" ? (
                      <a
                        target="_blank"
                        href={`https://wa.me/+5515996730404`}
                        className="w-full py-2 text-left px-4 flex justify-between items-center"
                        rel="noreferrer"
                      >
                        <span>Suporte e Assistência</span>
                      </a>
                    ) : (
                      <div className="w-full py-2 text-left px-4 flex justify-between items-center">
                        <span>Suporte e Assistência</span>
                        <Badge
                          color="gray"
                          className="ml-2"
                          style={{ fontSize: "8px" }}
                        >
                          Pro
                        </Badge>
                      </div>
                    )}
                  </Dropdown.Item>
                  <Dropdown.Item className="py-0 px-0">
                    <a
                      target="_blank"
                      href="https://wa.me/+5515996730404"
                      className="w-full py-2 text-left px-4"
                      rel="noreferrer"
                    >
                      Entre em contato
                    </a>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={removeToken}>Sair</Dropdown.Item>
                </Dropdown>
                <button
                  ref={menuBtnRef}
                  type="button"
                  onClick={handleToggleSidebar}
                  className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden focus:outline-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 md:hidden"
                >
                  <span className="sr-only">Abrir sidebar</span>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="#fff"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      fillRule="evenodd"
                      d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
