import Input from "../components/inputs/Input";
import { AiOutlineLoading } from "react-icons/ai";
import { Alert, Button, Tabs } from "flowbite-react";
import useCEP from "../hooks/useCEP";
import { useForm } from "react-hook-form";
import { PROFESSIONALS } from "../config/apiConfig";
import axios from "../config/axiosInstance";
import { useEffect, useState } from "react";
import { useApplication } from "../contexts/ApplicationContext";
import { useQuery } from "react-query";
import CPFInput from "../components/inputs/CPFInput";
import PhoneInput from "../components/inputs/PhoneInput";
import SelectInput from "../components/inputs/SelectInput";
import { HiAdjustments, HiUserCircle } from "react-icons/hi";
import ModalDelete from "../components/modals/ModalDelete";
import { useNavigate } from "react-router-dom";

const MyProfilePage = () => {
  const navigate = useNavigate();
  const { user } = useApplication();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(null);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [modalDeleteUser, setModalDeleteUser] = useState(false);

  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const {
    isLoading,
    error,
    data: professional,
  } = useQuery(["professional", user], async () => {
    if (user?.id) {
      const { data: item } = await axios.get(
        `${PROFESSIONALS.GET_PROFESSIONAL}/${user.id}`
      );
      return item.data;
    }
  });

  const {
    cep,
    setCep,
    address,
    loading: loadingCEP,
    error: errorCEP,
  } = useCEP();

  const onClickSearchCEP = (e) => {
    setCep(getValues("addressPostalCode"));
  };

  useEffect(() => {
    setValue("address", address?.logradouro);
    setValue("addressNeighborhood", address?.bairro);
    setValue("addressCity", address?.localidade);
  }, [address]);

  useEffect(() => {
    if (professional) {
      Object.keys(professional).forEach((key) => {
        setValue(key, professional[key]);
      });
    }
  }, [professional]);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      delete data.password;
      await axios.put(`${PROFESSIONALS.GET_PROFESSIONAL}/${user.id}`, data);
      setAlert({
        show: true,
        message: "Informações atualizadas com sucesso!",
        type: "success",
      });
    } catch (error) {
      setErrorSubmit(error);
      setAlert({
        show: true,
        message: "Erro ao tentar atualizar informações!",
        type: "failure",
      });
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          type: "",
        });
      }, 2000);
    }
  };

  const onSubmitChangePassword = async (data) => {
    try {
      setIsSubmitting(true);

      if (data.password !== data.password_confirm)
        throw new Error("Senhas divergentes");

      data = { id: data.id, password: data.password };

      await axios.put(`${PROFESSIONALS.GET_PROFESSIONAL}/${user.id}`, data);
      setAlert({
        show: true,
        message: "Senha atualizada com sucesso!",
        type: "success",
      });
    } catch (error) {
      setErrorSubmit(error);
      setAlert({
        show: true,
        message: "Erro ao tentar atualizar senha!",
        type: "failure",
      });
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          type: "",
        });
      }, 2000);
    }
  };

  const handleDeleteUser = () => {
    setModalDeleteUser(true);
  };

  const deleteUser = async () => {
    setIsSubmitting(true);
    try {
      await axios.delete(PROFESSIONALS.GET_PROFESSIONAL);
      localStorage.removeItem("token");
      navigate("/login");
    } catch (error) {
      setErrorSubmit(error);
      setAlert({
        show: true,
        message: "Erro ao tentar apagar usuário!",
        type: "failure",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-white px-4 py-4 rounded-md shadow-md">
      <Tabs aria-label="Default tabs">
        <Tabs.Item active title="Minha conta" icon={HiUserCircle}>
          <form className="mb-10" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6">
              <Input
                type="text"
                label="Nome *"
                name="name"
                required={true}
                register={register}
                error={errors?.name && "Este campo é obrigatório"}
              />
            </div>
            <div className="mb-6">
              <Input
                type="email"
                label="E-mail *"
                name="email"
                required={true}
                register={register}
                error={errors?.email && "Este campo é obrigatório"}
              />
            </div>
            <div className="mb-6">
              <PhoneInput
                type="text"
                label="Telefone *"
                name="phoneNumber"
                required={true}
                register={register}
                error={errors?.phoneNumber && "Este campo é obrigatório"}
              />
            </div>
            <div className="mb-6">
              <CPFInput
                type="text"
                label="CPF"
                name="cpf"
                required={false}
                register={register}
                value={watch("cpf") || ""}
              />
            </div>
            <div className="mb-6 flex gap-2">
              <Input
                type="text"
                label="CEP"
                name="addressPostalCode"
                required={false}
                register={register}
              />
              <Button
                onClick={onClickSearchCEP}
                className="flex align-center whitespace-nowrap primary"
                style={{ width: "220px", paddingTop: "4px" }}
                isProcessing={loadingCEP}
                processingSpinner={
                  <AiOutlineLoading className="h-6 w-6 animate-spin" />
                }
              >
                Buscar CEP
              </Button>
            </div>
            <div className="mb-6">
              <Input
                type="text"
                label="Endereço"
                name="address"
                required={false}
                register={register}
              />
            </div>
            <div className="mb-6">
              <div className="flex gap-4">
                <Input
                  type="text"
                  label="Número"
                  name="addressNumber"
                  required={false}
                  register={register}
                />
                <Input
                  type="text"
                  label="Bairro"
                  name="addressNeighborhood"
                  required={false}
                  register={register}
                />
              </div>
            </div>
            <div className="mb-6">
              <div className="flex gap-4">
                <Input
                  type="text"
                  label="Cidade"
                  name="addressCity"
                  required={false}
                  register={register}
                />
              </div>
            </div>
            <div className="mb-6">
              <div className="flex flex-col md:flex-row gap-4">
                <Input
                  type="date"
                  label="Data de nascimento"
                  name="birthday"
                  required={false}
                  register={register}
                />
                <div className="w-full relative">
                  <select
                    id="gender"
                    name="gender"
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    {...register("gender")}
                  >
                    <option value="">Selecione o gênero</option>
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                  </select>
                  <label
                    htmlFor="gender"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Sexo
                  </label>
                </div>
              </div>
            </div>

            <div className="mb-6">
              <SelectInput
                label="Cor da agenda"
                name="colorSchedule"
                required={false}
                register={register}
              >
                <option value="#ff9ea4">Vermelho</option>
                <option value="#9fc69a">Verde</option>
                <option value="#eac198">Marrom</option>
                <option value="#aae9ff">Azul</option>
                <option value="#b7b7b7">Cinza</option>
                <option value="#d8d497">Musgo</option>
                <option value="#ffc9ea">Rosa</option>
                <option value="#ffbf75">Laranja</option>
                <option value="#d7a8ff">Roxo</option>
                <option value="#e8e1a0">Amarelo</option>
              </SelectInput>
            </div>

            <Button
              type="submit"
              className="w-full primary"
              isProcessing={isSubmitting}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
              disabled={isSubmitting}
            >
              Atualizar perfil
            </Button>

            <Button
              onClick={handleDeleteUser}
              className="w-full bg-gray-600 mt-2"
              isProcessing={isSubmitting}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
              disabled={isSubmitting}
            >
              Apagar conta
            </Button>

            {alert.show && (
              <Alert className="mt-4" color={`${alert.type}`}>
                <span className="font-medium">{alert.message}</span>
              </Alert>
            )}
          </form>
        </Tabs.Item>
        <Tabs.Item title="Trocar senha" icon={HiAdjustments}>
          <form
            className="mb-10"
            onSubmit={handleSubmit(onSubmitChangePassword)}
          >
            <div className="mb-6">
              <Input
                type="password"
                label="Nova senha *"
                name="password"
                required={false}
                register={register}
                autocomplete="new-password"
              />
            </div>
            <div className="mb-6">
              <Input
                type="password"
                label="Confirmar senha *"
                name="password_confirm"
                required={false}
                register={register}
                autocomplete="new-password"
              />
            </div>
            <Button
              type="submit"
              className="w-full primary"
              isProcessing={isSubmitting}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
              disabled={isSubmitting}
            >
              Atualizar senha
            </Button>
            {alert.show && (
              <Alert className="mt-4" color={`${alert.type}`}>
                <span className="font-medium">{alert.message}</span>
              </Alert>
            )}
          </form>
        </Tabs.Item>
      </Tabs>

      {modalDeleteUser && (
        <ModalDelete
          title="Tem certeza que deseja apagar sua conta?"
          isOpen={modalDeleteUser}
          setOpenModal={setModalDeleteUser}
          handleDelete={deleteUser}
        >
          <div className="text-center text-sm">
            Esta ação é irreversível e seus dados serão tratados conforme
            descrito em nossa{" "}
            <a
              href="https://relinq.com.br/politica-de-privacidade"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              Política de Privacidade
            </a>
          </div>
          .
        </ModalDelete>
      )}
    </div>
  );
};

export default MyProfilePage;
