import React, { useEffect, useState } from "react";
import { Purchases } from "@revenuecat/purchases-capacitor";
import { Button } from "flowbite-react";
import { useNavigate } from "react-router-dom";

const SubscriptionInfoPageApp = () => {
  const navigate = useNavigate();
  const [customerInfo, setCustomerInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      try {
        await Purchases.configure({
          apiKey: "appl_uQEDSAvhxCxisCPYqGlswnxIJCP",
        });
        const info = await Purchases.getCustomerInfo();
        setCustomerInfo(info.customerInfo);
      } catch (error) {
        console.error("Erro ao obter informações do cliente:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const displaySubscriptionInfo = () => {
    if (customerInfo) {
      const activeSubscriptions = customerInfo?.activeSubscriptions;
      const managementURL = customerInfo?.managementURL;

      return (
        <div>
          {activeSubscriptions && activeSubscriptions.length > 0 ? (
            activeSubscriptions.map((subscription, index) => (
              <div key={index}>
                <div className="rounded-lg bg-primary w-20 h-20 flex items-center justify-center p-4 mx-auto mb-2">
                  <img src="/assets/logo_mini.png" />
                </div>
                <p className="font-semibold text-center">
                  Assinatura Relinq PRO
                </p>
                <p className="text-center">
                  <strong>Ativa até:</strong>{" "}
                  {new Date(
                    customerInfo.allExpirationDates[subscription]
                  ).toLocaleDateString()}
                </p>
                <p className="text-center">
                  <strong>Renovação automática:</strong> Sim
                </p>
                {managementURL && (
                  <div className="mt-4 text-center">
                    <Button
                      onClick={() => window.open(managementURL, "_blank")}
                      className="w-full primary"
                    >
                      Cancelar Assinatura
                    </Button>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>Você não possui assinaturas ativas.</p>
          )}
        </div>
      );
    }
    return <p>Carregando informações...</p>;
  };

  return (
    <div className="flex flex-col min-h-screen p-4">
      <h1 className="text-xl font-bold text-center">Informações da Assinatura</h1>
      {loading ? <div>Carregando...</div> : displaySubscriptionInfo()}
      <Button className="mt-2" onClick={() => navigate("/dashboard")}>
        Voltar para o Dashboard
      </Button>
    </div>
  );
};

export default SubscriptionInfoPageApp;
